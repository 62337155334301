import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lib-loading-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './lib-loading-icon.component.html',
  styleUrl: './lib-loading-icon.component.scss',
})
export class LibLoadingIconComponent {}
